import AddCardIcon from '@mui/icons-material/AddCard';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import HardwareIcon from '@mui/icons-material/Hardware';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DeleteIcon from '@mui/icons-material/Delete';
import RecyclingIcon from '@mui/icons-material/Recycling';

export const reqCompensationOptions = [
	{ value:'', label:'-- Please choose a valid option --'},
	{ value:'exchange', label:'Exchange' },
	{ value:'credit-note', label:'Credit note' },
]
export const exchangeReasonOptions = [
	{ value:'', label:'-- Please choose a valid option --'},
	{ value:'defective', label:'Defective' },
	{ value:'over-delivery', label:'Over-delivery' },
	{ value:'incorrect-delivery', label:'Incorrect delivery' },
	{ value:'fair-return', label:'Fair return' },
	{ value:'warehouse-dissolution', label:'Warehouse dissolution' },
	{ value:'technical-analysis', label:'Technical analysis' },
	{ value:'transport-damage', label:'Transport damage' },
	{ value:'acceptance-refusal', label:'Refusal of acceptance' },
]
export const defectCategoryOptions = [
	{ value:'', label:'-- Please choose a valid option --'},
	{ value:'out-of-warranty', label:'Out of warranty' },
	{ value:'internal-leakage', label:'Internal leakage' },
	{ value:'extended', label:'Extended' },
	{ value:'closing-time', label:'Closing time' },
]
export const producerOptions = [
	{ value:'aht', label:'AHT' },
	{ value:'fremdfirma', label:'Fremdfirma' },
]

export const statuses = [
	{
		value:'created',
		label:'Created',
		icon: <AddCardIcon sx={{ verticalAlign:'middle', mr:1 }} />,
	},
	{
		value:'deleted',
		label:'Deleted',
		icon: <DeleteIcon sx={{ verticalAlign:'middle', mr:1 }} />,
	},
	{
		value:'product-support-approved',
		label:'Approved by product support',
		icon: <DoneIcon sx={{ verticalAlign:'middle', mr:1 }} />,
	},
	{
		value:'product-support-rejected',
		label:'Rejected by product support',
		icon: <ClearIcon sx={{ verticalAlign:'middle', mr:1 }} />,
	},
	{
		value:'service-management-approved',
		label:'Approved by management',
		icon: <DoneIcon sx={{ verticalAlign:'middle', mr:1 }} />,
	},
	{
		value:'service-management-rejected',
		label:'Rejected by management',
		icon: <ClearIcon sx={{ verticalAlign:'middle', mr:1 }} />,
	},
	{
		value:'quality-management-scrape',
		label:'Scrape requested by QM',
		icon: <HardwareIcon sx={{ verticalAlign:'middle', mr:1 }} />,
	},
	{
		value:'quality-management-collect',
		label:'Retrival requested by QM',
		icon: <LocalShippingIcon sx={{ verticalAlign:'middle', mr:1 }} />,
	},
	{
		value:'quality-management-rejected',
		label:'Rejected by QM',
		icon: <ClearIcon sx={{ verticalAlign:'middle', mr:1 }} />,
	},
	{
		value:'completed',
		label:'Completed',
		icon: <DoneIcon sx={{ verticalAlign:'middle', mr:1 }} />,
	},
	{
		value:'reopened',
		label:'Reopened',
		icon: <RecyclingIcon sx={{ verticalAlign:'middle', mr:1 }} />,
	},
	{
		value:'deleted',
		label:'Deleted',
		icon: <DeleteIcon sx={{ verticalAlign:'middle', mr:1 }} />,
	},
]

export const actionsDef = [
	{
		tag: 'submit',
		label: 'Submit',
		icon: <AddCardIcon />,
	},
	{
		tag: 'product-support-approve',
		label: 'PS Approve',
		icon: <DoneIcon />,
		productSupportRelated: true,
	},
	{
		tag: 'product-support-reject',
		label: 'PS Reject',
		icon: <ClearIcon />,
		productSupportRelated: true,
	},
	{
		tag: 'service-management-approve',
		label: 'MGT Approve',
		icon: <DoneIcon />,
	},
	{
		tag: 'service-management-reject',
		label: 'MGT Reject',
		icon: <ClearIcon />,
	},
	{
		tag: 'quality-management-scrape',
		label: 'Scrape',
		confirmMsg: 'Are you sure you want to scrape the unit?',
		icon: <HardwareIcon />,
	},
	{
		tag: 'quality-management-collect',
		label: 'Retrieve unit',
		confirmMsg: 'Are you sure you want to retrieve the unit?',
		icon: <LocalShippingIcon />,
	},
	{
		tag: 'quality-management-reject',
		label: 'QM Reject',
		confirmMsg: 'Are you sure you want to reject the request?',
		icon: <ClearIcon />,
	},
	{
		tag: 'complete',
		label: 'Complete',
		confirmMsg: 'Are you sure you want to complete the request and notify the partner?',
		icon: <DoneIcon />,
	},
	{
		tag: 'reopen',
		label: 'Reopen',
		confirmMsg: 'Are you sure you want to reopen the request?',
		icon: <RecyclingIcon />,
	},
	{
		tag: 'delete',
		label: 'Delete',
		confirmMsg: 'Are you sure you want to delete the request?',
		icon: <DeleteIcon />,
	},
]
