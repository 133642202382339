import React, { useState, useEffect, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';

const HeadRow = ({ def, actionsAvailable }) => (
	<TableRow>
		{ def.map((colDef, colIdx) => (
			<TableCell key={colIdx} sx={{ fontWeight:'bold' }}>{colDef?.label || ''}</TableCell>
		))}
		{ actionsAvailable && <TableCell key="_actions"></TableCell> }
	</TableRow>
)

const SkeletonRow = ({ def }) => (
	<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		{ def.map((colDef, colIdx) => (
			<TableCell key={colIdx}><Skeleton /></TableCell>
		))}
	</TableRow>
)

const DataCell = params => {
	const { colDef, row } = params

	const content = useMemo(() => {
		if(colDef.hasOwnProperty('content'))
			return colDef.content(params)
		return ''

		// eslint-disable-next-line
	}, [ colDef, row ])

	return <TableCell>{content}</TableCell>
}

const RowActions = ({ actions, ...params }) => {
	const content = useMemo(() => {
		return actions(params)

		// eslint-disable-next-line
	}, [ params.row ])

	return <TableCell>{content}</TableCell>
}

const DataRow = ({ def, _id, fetchRow, actions }) => {
	const [ row, setRow ] = useState(null)

	const handleRowRefresh = () => fetchRow(_id).then(setRow)
	useEffect(() => {
		handleRowRefresh()

		// eslint-disable-next-line
	}, [ _id, fetchRow ])

	return row === null ? <SkeletonRow def={def} /> : (
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
			{ def.map((colDef, colIdx) => (
				<DataCell
					key={colIdx}
					colDef={colDef}
					_id={_id}
					row={row}
					handleRowRefresh={handleRowRefresh}
				/>
			))}
			{ Boolean(actions) && (
				<RowActions
					_id={_id}
					row={row}
					actions={actions}
					handleRowRefresh={handleRowRefresh}
				/>
			)}
		</TableRow>
	)
}

export default function Data({ def, children, scan, fetchRow, actions }) {
	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead>
					<HeadRow def={def} actionsAvailable={Boolean(actions)} />
				</TableHead>
				<TableBody>
					{ children }
					{ scan?.items?.map(_id => (
						<DataRow key={_id} def={def} _id={_id} fetchRow={fetchRow} actions={actions} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}
