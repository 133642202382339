import React, { useState } from 'react'
import { Link } from "react-router-dom"
import LoadingButton from '@mui/lab/LoadingButton'

export default function Button({ label, onClick, linkTo, icon, sx={}, disabled }) {
	const [ loading, setLoading ] = useState(false)

	const handleClick = async event => {
		setLoading(true)
		await onClick?.(event)
		setLoading(false)
	}

	const btnParams = {
		sx, disabled,
		onClick:handleClick,
	}
	if(linkTo) {
		btnParams.component = Link
		btnParams.to = linkTo
	}

	return (
		<LoadingButton
			{...btnParams}
			variant="contained"
			startIcon={icon}
			loading={loading}
			loadingPosition={icon ? "start" : "center"}
		>
			{label}
		</LoadingButton>
	)
}
