import React, { useState, useContext } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { useTheme as useThemeMui } from '@mui/material/styles';

// import Backdrop from '@mui/material/Backdrop';
// import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import SnackbarMui from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

// import Button from '@mui/material/Button';
// import DialogMui from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';

// import APICtx from './api'
import { useMsg } from '../notify';
// import ToolsCtx from '../App'
import HeadBar from './HeadBar'
import SideBar from './SideBar'
// import config from '../config.json'
import config from '#config'

// const { NODE_ENV } = process.env
const theme = createTheme(config?.theme || {})

export const TplConfCtx = React.createContext(null)

// export const useTheme = () => {
// 	const theme = useThemeMui()

// 	const lib = {
// 		getMode: () => theme.palette.mode,
// 		getColor: (palette) => theme.palette?.[palette]?.[lib.getMode()] || theme.palette?.[palette],
// 	}
// 	return lib
// }

const Snackbar = () => {
	const [ msg, setMsg ] = useState(null)
	const [ open, setOpen ] = useState(false)

	useMsg(msg => {
		setOpen(true)
		setMsg(msg)
	})

	const handleClose = (e, reason) =>
		reason!=='clickaway' && setOpen(false)

	return (
		<SnackbarMui
			open={open}
			autoHideDuration={6000}
			onClose={handleClose}
			anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
		>
			<Alert onClose={handleClose} severity={msg?.severity || 'info'} sx={{ width: '100%' }}>
				{msg?.message}
			</Alert>
		</SnackbarMui>
	)
}

const Screen = ({ menu, logo, children }) => {
	// const api = useContext(APICtx)
	// const [ scrollPosition, setScrollPosition ] = useState(0)

	// const handleScroll = () => setScrollPosition(window.pageYOffset)
	// useEffect(() => {
	// 	window.addEventListener('scroll', handleScroll, { passive: true })
	// 	return () => {
	// 		window.removeEventListener('scroll', handleScroll)
	// 	}
	// }, [])

	return (
		<TplConfig menu={menu} logo={logo}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{/* <Backdrop open={!api.isReady}>
					<CircularProgress color="inherit" />
				</Backdrop> */}
				<Snackbar />
				<Router>
					{/* { api.isReady && children } */}
					{children}
				</Router>
			</ThemeProvider>
		</TplConfig>
	)
}
export default Screen

const tplConfigProps = [
	'menu',
	'logo',
	// 'backTo',
]
export const TplConfig = ({ children, ...props }) => {
	const conf = useContext(TplConfCtx) || {}
	tplConfigProps.forEach(propName => {
		conf[propName] = props[propName] || conf[propName]
	})

	return (
		<TplConfCtx.Provider value={conf}>
			{children}
		</TplConfCtx.Provider>
	)
}

const useTitle = title => {
	const base = config?.title ? config?.title : ''
	return base + (title ? ' - ' + title : '')
}

export const TplWSidebar = ({ title, children }) => {
	const tplConf = useContext(TplConfCtx)
	const titleDisplay = useTitle(title)
	const [ mobileSidebarOpen, setMobileSidebarOpen ] = useState(false)

	const sidebarWidth = config.sidebarWidth
	const toolbarHeight = config.toolbarHeight

	const toggleMobileSidebar = open => event => {
		if(event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift'))
			return
		setMobileSidebarOpen(open)
	}

	return (
		<>
			<HeadBar
				title={titleDisplay}
				sidebarWidth={sidebarWidth}
				toolbarHeight={toolbarHeight}
				toggleSidebar={toggleMobileSidebar}
				sidebarOpen={mobileSidebarOpen}
			/>
			<Toolbar />
			<Box
				component="nav"
				sx={{
					width: { md: sidebarWidth },
					flexShrink: { md: 0 } }}
			>
				<SideBar
					sidebarWidth={sidebarWidth}
					menu={tplConf.menu}
					logo={tplConf.logo}
					toolbarHeight={toolbarHeight}
					toggleSidebar={toggleMobileSidebar}
					sidebarOpen={mobileSidebarOpen}
				/>
			</Box>
			<Box sx={{
				p: 1,
				flexGrow: 1,
				width: { md: `calc(100% - ${sidebarWidth})` },
				ml: { md: sidebarWidth },
			}}>
				{children}
			</Box>
		</>
	)
}

export const TplCentralBox = ({ title, children, maxWidth=400, backTo }) => {
	const tplConf = useContext(TplConfCtx)
	const titleDisplay = useTitle(title, tplConf.titlePrefix)

	// const sidebarWidth = config.sidebarWidth
	const toolbarHeight = config.toolbarHeight

	return (
		<Box sx={{ display: 'flex' }}>
			<HeadBar
				title={titleDisplay}
				menu={tplConf.tools}
				toolAuth={tplConf.toolAuth}
				logo={tplConf.logo}
				backTo={backTo}
				toolbarHeight={toolbarHeight}
			/>
			<Box
				justifyContent="center"
				alignItems="center"
				component="main" 
				sx={{ flexGrow:1, bgcolor:'background.default', p:3 }}
			>
				<Toolbar />
				<Box sx={{ m:'auto', maxWidth }}>
					{children}
				</Box>
			</Box>
		</Box>
	)
}
