import React, { useEffect, useMemo, useState } from 'react';

import { request, useAuth } from '#api';
import Form, { InputText, InputMoney } from '#Form';
import Dialog from '#Dialog';
import DialogTitle from './DialogTitle';

export const SparepartsForm = ({ _id, handleClose }) => {
	const auth = useAuth()
	const [ data, setData ] = useState({})

	useEffect(() => {
		_id && request('/cabinet-exchange/spare-parts/get', { _id }).then(setData)
	}, [ _id ])

	const onSubmit = async () => {
		await request('/cabinet-exchange/spare-parts/set', { ...data, _id })
		handleClose?.()
	}

	const aclRW = auth.check('spare-parts')

	const formProps = {
		_id,
		data, setData,
		onSubmit: _id && aclRW && onSubmit,
		submitLabel: 'Save',
	}

	return (
		<Form {...formProps}>
			<InputText label="OC number" name="ocNumber" disabled={!aclRW} />
			<InputText label="Credit note number" name="creditNoteNumber" disabled={!aclRW} />
			<InputMoney label="Credit note amount" name="creditNoteAmount" disabled={!aclRW} />
		</Form>
	)
}

export const SparepartsDialog = ({ _id, item, handleClose }) => {
	const open = useMemo(
		() => Boolean(_id),
		[ _id ],
	)

	return (
		<Dialog
			handleClose={handleClose}
			open={open}
			title={<DialogTitle item={item} title="Spare parts" />}
			width="md"
		>
			<SparepartsForm _id={_id} handleClose={handleClose} />
		</Dialog>
	)
}
