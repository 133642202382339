import React, { useMemo, useEffect } from 'react';
import { useNavigate, Routes, Route, useLocation } from "react-router-dom";
import Screen, { TplWSidebar as Tpl } from '#Template'

import logoImg from './logo.png'

import LoginPage from './pages/Login'
import CabinetExchangeCreatePage from './pages/cabinetExchange/Create'
import CabinetExchangeListPage from './pages/cabinetExchange/List'
import { request, setAuthToken, useAuth } from '#api';

import AddCardIcon from '@mui/icons-material/AddCard';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

const Router = () => {
	const auth = useAuth()
	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		if(auth.check('request-view') && location.pathname === '/')
			navigate('/request/list')
		if(!auth.check('request-view') && auth.check('request-create') && location.pathname !== '/request/create')
			navigate('/request/create')
	}, [ auth, location, navigate ])

	return (
		<Routes>
			<Route path="request/view/:_id" element={<CabinetExchangeCreatePage />} />
			<Route path="request/create" element={<CabinetExchangeCreatePage />} />
			<Route path="request/list" element={<CabinetExchangeListPage />} />

			<Route path="/" element={<Blank />} />
		</Routes>
	)
}

const Blank = () => (
	<Tpl />
)

const useMenu = (api) => {
	const auth = useAuth()
	return useMemo(() => {
		const menu = []

		if(auth.check('request-create'))
			menu.push({
				label: "New request",
				linkTo: "/request/create",
				icon: <AddCardIcon />,
			})
		if(auth.check('request-view'))
			menu.push({
				label: "Archive",
				linkTo: "/request/list",
				icon: <FormatListBulletedIcon />,
			})

		return menu
	}, [ auth ])
}

const Autologin = ({ children }) => {
	const location = useLocation()

	const handleAutologin = async token => {
		const session = await request('/auth/autologin/evaluate', { token }).catch(() => null)
		session && setAuthToken(session)
	}

	useEffect(() => {
		const params = new URLSearchParams(location.search)
		const autoLoginToken = params.get('_autologin')
		autoLoginToken && handleAutologin(autoLoginToken)
	}, [ location ])

	return children
}

export default function App() {
	const auth = useAuth()
	const menu = useMenu()

	return (
		<Screen logo={logoImg} menu={menu}>
			<Autologin>
				{auth.check() ? <Router /> : <LoginPage />}
			</Autologin>
		</Screen>
	)
}
