import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TplWSidebar as Tpl } from '#Template'
import Form, { InputText, InputPassword } from '#Form';
import { setAuthToken, request } from '#api';

import LoginIcon from '@mui/icons-material/Login';
import Button from '#Button';

export default function Login() {
	const [ data, setData ] = useState({})
	const navigate = useNavigate()

	const onSubmit = async () => {
		const ret = await request('/auth/login', data)
		ret && setAuthToken(ret)
		navigate('/', { replace:false })
	}

	const formProps = {
		data, setData,
		onSubmit,
		submitLabel: 'Login',
		submitIcon: <LoginIcon />,
	}

	return (
		<Tpl title="Login">
			<Form {...formProps}>
				<InputText required label="Username" name="username" />
				<InputPassword required label="Password" name="password" />
			</Form>
			<Button label="auto-fill [partner0]" onClick={() => setData({ username:'partner0', password:'test1234' })} />
			<Button label="auto-fill [pSupport0]" onClick={() => setData({ username:'pSupport0', password:'test1234' })} />
			<Button label="auto-fill [svcMgt0]" onClick={() => setData({ username:'svcMgt0', password:'test1234' })} />
			<Button label="auto-fill [qm0]" onClick={() => setData({ username:'qm0', password:'test1234' })} />
			<Button label="auto-fill [spareParts0]" onClick={() => setData({ username:'spareParts0', password:'test1234' })} />
			<Button label="auto-fill [admin]" onClick={() => setData({ username:'admin', password:'test1234' })} />
		</Tpl>
	)
}
