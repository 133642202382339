import React, { useState, useEffect } from 'react';
import { Typography, Skeleton } from '@mui/material';
import { request } from '#api';

export const DisplayUser = ({ uid }) => {
	const [ label, setLabel ] = useState(null)

	useEffect(() => {
		uid ? request('/auth/user/get', { uid }).then(user => setLabel(user?.label || null)) : setLabel(null)
	}, [ uid ])

	return (
		<Typography component='span'>
			{ label === null ? (
				<Skeleton width={240} />
			) : label }
		</Typography>
	)
}

export const DisplayDate = ({ ts }) => {
	const [ label, setLabel ] = useState(null)

	useEffect(() => {
		const label = ts ? displayDate(ts) : null
		setLabel(label)
	}, [ ts ])

	return (
		<Typography component='span'>
			{ label === null ? (
				<Skeleton width={240} />
			) : label }
		</Typography>
	)
}

export const DisplayDateTime = ({ ts }) => {
	const [ label, setLabel ] = useState(null)

	useEffect(() => {
		const label = ts ? displayDateTime(ts) : null
		setLabel(label)
	}, [ ts ])

	return (
		<Typography component='span'>
			{ label === null ? (
				<Skeleton width={240} />
			) : label }
		</Typography>
	)
}

export const displayDate = (input) => {
	const date = typeof(input) === 'object' ? input : new Date(input)
	return date.getDate().toString().padStart(2, '0') +
		'/' + (date.getMonth() + 1 ).toString().padStart(2, '0') +
		'/' + date.getFullYear().toString()
}

export const displayDateTime = (input) => {
	const date = typeof(input) === 'object' ? input : new Date(input)
	return date.getDate().toString().padStart(2, '0') +
		'/' + (date.getMonth() + 1 ).toString().padStart(2, '0') +
		'/' + date.getFullYear().toString() +
		' ' + date.getHours().toString() +
		':' + date.getMinutes().toString()
}
