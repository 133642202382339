import React from 'react';
import { Link } from "react-router-dom";
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const libSX = {
	minHeight: 48,
	justifyContent: 'initial',
	px: 1,
}

const liiSX = {
	minWidth: 32,
	ml: 1,
	mr: 2,
	justifyContent: 'center',
}

const MenuSection = ({ def }) => (
	<List>
		{ def.map(({ label, icon, linkTo }, itemIdx) => (
			<ListItem key={itemIdx} disablePadding sx={{ display:'block' }}>
			<ListItemButton component={Link} to={linkTo} sx={libSX}>
				{ Boolean(icon) && (
					<ListItemIcon sx={liiSX}>
						{icon}
					</ListItemIcon>
				)}
				<ListItemText primary={label} />
			</ListItemButton>
			</ListItem>
		)) }
	</List>
)

const Menu = ({ def }) => {
	if(!def && !def.length)
		return null
	if(!Array.isArray(def[0]))
		return <MenuSection def={def} />
	else
		return def.reduce((acc, cur, index) => {
			if(index>0)
				acc.push(<Divider key={'div-'+index} />)
			acc.push(<MenuSection key={'item-'+index} def={cur} />)
			return acc
		}, [])
}

const ToolbarContent = ({ logo, sidebarWidth, toolbarHeight }) => {
	const imgStyle = {
		height: toolbarHeight,
		width: `calc(${sidebarWidth} - 48px)`,
		objectFit: "contain",
	}
	
	return (
		<Grid
			sx={{ height:toolbarHeight, px:1, overflow:'hidden' }}
			container
			direction="row"
			justifyContent="space-around"
			alignItems="center"
		>
			<Grid item sx={{ flexGrow:1, textAlign:'center' }}>
				{ logo && (
					<img
						alt="logo"
						src={logo}
						style={imgStyle}
					/>
				)}
			</Grid>
		</Grid>
	)
}

const Content = ({ sidebarWidth, toolbarHeight, menu, logo, toggleSidebar }) => (
	<>
		<Toolbar disableGutters>
			<ToolbarContent
				logo={logo}
				sidebarWidth={sidebarWidth}
				toolbarHeight={toolbarHeight}
			/>
		</Toolbar>
		<Divider />
		<Box
			onClick={toggleSidebar(false)}
			onKeyDown={toggleSidebar(false)}
			sx={{ width:sidebarWidth }}
		>
			{ menu && <Menu def={menu} />}
		</Box>
	</>
)

const SideBar = params => (
	<>
		<Drawer
			variant="permanent"
			open
			sx={{
				display: { xs:'none', md:'block' },
			}}
		>
			<Content {...params} sidebarOpen={true} />
		</Drawer>
		<Drawer
			anchor="left"
			open={params.sidebarOpen}
			onClose={params.toggleSidebar(false)}
			sx={{
				display: { xs:'block', md:'none' },
			}}
		>
			<Content {...params} />
		</Drawer>
	</>
)
export default SideBar