import React, { useState, useContext, createContext, useEffect, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import SelectMui from '@mui/material/Select';
import CheckboxMui from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment'
import { List, ListItem, ListItemText, Button as ButtonMui } from '@mui/material'

import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';

import Button from './Button'
import ButtonPad from './ButtonPad'
import { request, requestDownload, requestUpload } from './api'

export const FormCtx = createContext({})

export const useData = name => {
	const ctx = useContext(FormCtx)
	// console.log('form-get', ctx.data, name)
	return dataExtract(ctx.data, name.split('.'))
}
export const useSetData = name => {
	const ctx = useContext(FormCtx)
	return value => {
		const clonedData = ctx.data ? JSON.parse(JSON.stringify(ctx.data)) : {}
		const newData = dataPatch(clonedData, name.split('.'), value)
		// console.log('form-set', ctx.data, name, value, newData)
		ctx.setData(newData)
		return value
	}
}
export const dataExtract = (data, path) => {
	const step = path.shift()
	if(data?.[step])
		return path.length ? dataExtract(data[step], path) : data[step]
	return ''
}
export const dataPatch = (data, path, value) => {
	if(!path.length)
		return value
	const step = path.shift()
	if(!data || typeof(data)!=='object')
		data = {}
	data[step] = dataPatch(data[step], path, value)
	return data
}

export default function Form({ children, data, setData, onSubmit, submitLabel="Save", submitIcon=<SaveIcon />, sx={} }) {
	const [ saveDone, setSaveDone ] = useState(false)
	const [ error, setError ] = useState(false)
	const [ validationErrors, setValidationErrors ] = useState({})

	const ctx = { data, setData, validationErrors }

	const handleSubmit = async (e) => {
		e.preventDefault()
		await onSubmit?.()
			.then(() => {
				setSaveDone(true)
				setError(false)
				setValidationErrors({})
			})
			.catch(error => {
				setError(true)
				const validationErrors = error.errorType === 'invalid-parameters' ? error.subError : {}
				setValidationErrors(validationErrors)
			})
	}

	const isReady = true
	const submitIconEval = Boolean(error) ? <ReportProblemIcon /> : (saveDone ? <CheckIcon /> : submitIcon)
	return (
		<Box component="form" onSubmit={handleSubmit} sx={{ p:1, ...sx }}>
			<FormCtx.Provider value={ctx}>
				{isReady && children}
			</FormCtx.Provider>
			{ Boolean(onSubmit) && (
				<Box sx={{ display:"flex", justifyContent:"right" }}>
					<Button label={submitLabel} icon={submitIconEval} onClick={handleSubmit} />
				</Box>
			)}
		</Box>
	)
}

export const FormSection = ({ title, children }) => (
	<Paper sx={{ p:1, mb:1 }}>
		{ Boolean(title) && <Typography variant="h6" sx={{ mb:1 }}>{title}</Typography> }
		{children}
	</Paper>
)

// ERRORS
export const useErrors = name => {
	const ctx = useContext(FormCtx)
	return ctx.validationErrors && ctx.validationErrors[name] && ctx.validationErrors[name].join(', ')
}
const ErrorHelper = ({ error }) =>
	Boolean(error) && <FormHelperText error={true}>{error}</FormHelperText>

// INPUT TYPES
export const InputRaw = ({ label, name, value, setValue, required, disabled, type, multilineRows, suffix }) => {
	const error = useErrors(name)

	const handleChange = event => {
		setValue(event.target.value)
	}

	let endAdornment = undefined
	if(suffix)
		endAdornment = (
			<InputAdornment position="end">
				{suffix}
			</InputAdornment>
		)

	const propsInput = {
		required,
		disabled,
		label,
		value,
		error: Boolean(error),
		type,
		onChange: handleChange,
		endAdornment,
	}
	if(multilineRows) {
		propsInput.multiline = true
		propsInput.rows = multilineRows
	}

	const propsLabel = { required, error:Boolean(error) }
	type==='date' && (propsLabel.shrink=true)

	return (
		<FormControl sx={{ mb:1 }} variant="outlined" error={Boolean(error)} fullWidth>
			<InputLabel {...propsLabel}>{label}</InputLabel>
			<OutlinedInput {...propsInput} />
			<ErrorHelper error={error} />
		</FormControl>
	)
}

export function InputText(props) {
	const value = useData(props.name)
	const setValue = useSetData(props.name)

	return <InputRaw
		{...props}
		type="text"
		value={value}
		setValue={setValue}
	/>
}

export function InputMoney(props) {
	const [ displayValue, setDisplayValue ] = useState('')
	const value = useData(props.name)
	const setValue = useSetData(props.name)

	const str2num = value => {
		const displayValue = value?.length ? value.replace(/[^0-9,]/g, '') : '0'
		const cleanedValue = displayValue.replace(/,/g, '.')
	    const numericValue = parseFloat(cleanedValue) * 10000
    	const finalValue = Math.round(numericValue)
		return finalValue
	}
	const num2str = value => {
		const numericValue = value / 10000
		let finalValue = numericValue.toString().replace(/\./g, ',')
		if(!finalValue.includes(','))
			finalValue += ',00'
		else {
			const decimalPart = finalValue.split(',')[1]
			if(decimalPart.length === 1)
				finalValue += '0'
		}
		return finalValue
	}

	useEffect(() => {
		const evalDisplay = str2num(displayValue)
		const evalValue = value || 0
		if(evalDisplay !== evalValue)
			setDisplayValue(num2str(value))
	}, [ value ])

	const handleSet = displayValue => {
		setDisplayValue(displayValue)
		const finalValue = str2num(displayValue)
		finalValue !== value && setValue(finalValue)
	}

	return <InputRaw
		{...props}
		type="text"
		suffix="€"
		value={displayValue}
		setValue={handleSet}
	/>
}

export function InputMultiline(props) {
	const value = useData(props.name)
	const setValue = useSetData(props.name)

	const minLines = props.disabled ? 1 : 3
	const curLinesQty = value ? value?.split("\n").length : 0
	const multilineRows = curLinesQty < minLines ? minLines : curLinesQty

	return <InputRaw
		{...props}
		type="text"
		multilineRows={multilineRows}
		value={value}
		setValue={setValue}
	/>
}

export const InputPassword = (props) => {
	const value = useData(props.name)
	const setValue = useSetData(props.name)

	return <InputRaw
		{...props}
		type="password"
		value={value}
		setValue={setValue}
	/>
}

export function InputDate(props) {
	const value = useData(props.name)
	const setValue = useSetData(props.name)

	return <InputRaw
		{...props}
		type="date"
		value={value}
		setValue={setValue}
	/>
}

export function Select({ name, required, disabled, label, options }) {
	const value = useData(name)
	const setValue = useSetData(name)
	const error = useErrors(name)

	const handleChange = (e) =>
		setValue(e.target.value || null)

	const props = {
		required,
		disabled,
		label,
		error,
		onChange: handleChange,
		value,
	}

	const items = options.map((opt, optIdx) => (
		<MenuItem key={optIdx} value={opt.value || ''}>
			<Grid container direction="row" justifyContent="space-between">
				<Grid item>
					{ Boolean(opt.icon) && (
						<Typography component="span" sx={{ mr:1, verticalAlign:'middle' }} gutterBottom>
							{opt.icon}
						</Typography>
					) }
					{opt.label || opt.value}
				</Grid>
			</Grid>
		</MenuItem>
	))

	return (
		<FormControl sx={{ mb:1, minWidth:120 }} disabled={disabled} fullWidth>
			<InputLabel required={required} error={error}>{label}</InputLabel>
			<SelectMui {...props}>
				{items}
			</SelectMui>
			<ErrorHelper error={error} />
		</FormControl>
	)
}

export function Checkbox({ label, name, onChange, disabled, required, valueOn=true, valueOff=false }) {
	const value = useData(name)
	const setValue = useSetData(name)
	const error = useErrors(name)

	const handleChange = (e) => {
		const newValue = e.target.checked ? valueOn : valueOff
		setValue(newValue)
	}

	if(required)
		label += '*'
	return (
		<FormControl sx={{ mb:1 }} fullWidth component="fieldset" disabled={disabled} error={error}>
			<FormControlLabel
				checked={value === valueOn}
				control={<CheckboxMui />}
				label={label}
				onChange={handleChange}
				disabled={Boolean(disabled)}
			/>
			<ErrorHelper error={error} />
		</FormControl>
	)
}

const FileAttached = ({ _id, handleRemove, getEndpoint, downloadEndpoint, disabled }) => {
	const [ fileInfo, setFileInfo] = useState({})

	useEffect(() => {
		_id && getEndpoint && request(getEndpoint, { _id })
			.then(setFileInfo)
			.catch(() => handleRemove())
	}, [ _id, getEndpoint ])

	const size = useMemo(() => {
		if(!fileInfo._size)
			return '-'
		return Math.round(fileInfo._size / 1024).toString() + ' KB'
	}, [ fileInfo ])

	const actions = useMemo(() => {
		const def = []

		if(downloadEndpoint)
			def.push({
				label: "Download",
				icon: <DownloadIcon />,
				onClick: () => requestDownload(downloadEndpoint, { _id }),
			})

		if(!disabled)
			def.push({
				label: "Remove",
				icon: <DeleteIcon />,
				onClick: handleRemove,
			})

		return def
	}, [ _id, downloadEndpoint ])

	return (
		<ListItem
			secondaryAction={
				<ButtonPad def={actions} />
			}
		>
			<ListItemText
				primary={fileInfo._filename || _id}
				secondary={`Size: ${size}`}
			/>
		</ListItem>
	)
}

export const File = ({ label, name, uploadEndpoint, getEndpoint, downloadEndpoint, disabled }) => {
	const value = useData(name)
	const setValue = useSetData(name)
	const error = useErrors(name)

	const onDrop = async (newFiles) => {
		for(const file of newFiles) {
			const _id = await requestUpload(uploadEndpoint, file)
			setValue(value?.length ? [ ...value, _id ] : [ _id ])
		}
	}

	const removeFile = (_id) => {
		setValue(value?.filter(item => item !== _id) || [])
	}

	const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: true })

	return (
		<Box sx={{}}>
			{label && (
				<Typography variant="body2" component="div" gutterBottom>
					{label}
				</Typography>
			)}

			{ Boolean(!disabled) && (
				<>
					<Box
						{...getRootProps()}
						sx={{
							border: '2px dashed gray',
							borderRadius: '8px',
							padding: '20px',
							backgroundColor: '#f5f5f5',
							textAlign: 'center',
							cursor: 'pointer',
							'&:hover': { backgroundColor: '#e0e0e0' },
						}}
						>
						<input {...getInputProps()} />
						<CloudUploadIcon fontSize="large" color="action" />
						<Typography variant="body1" color="textSecondary">
							Drag & drop files here, or click to select files
						</Typography>
						<ButtonMui variant="outlined" sx={{ mt: 2 }}>
							Browse Files
						</ButtonMui>
					</Box>
					<ErrorHelper error={error} />
				</>
			)}

			{value?.length > 0 && (
				<List>
					{value.map(_id => (
						<FileAttached
							key={_id}
							handleRemove={() => removeFile(_id)}
							_id={_id}
							getEndpoint={getEndpoint}
							downloadEndpoint={downloadEndpoint}
							disabled={disabled}
						/>
					))}
				</List>
			)}
		</Box>
	)
}
