import React, { useMemo } from 'react';

const DialogTitle = ({ item, title }) => {
	return useMemo(() => {
		const slices = []

		item?._registry.num && slices.push(item._registry.num)
		item?.device.serial && slices.push('device: ' + item.device.serial)
		title && slices.push(title)

		return slices.join(' - ')
	}, [ item ],
	)
}
export default DialogTitle