class ErrorCustom extends Error {
	subError
  
	constructor(msg, subError) {
		super(msg)
		this.subError = subError
	}
}

export class ErrorServiceUnavailable extends ErrorCustom {
	errorType = "service-unavailable"
	sentryError = "unavailable"
}

export class ErrorForbidden extends ErrorCustom {
	errorType = "forbidden"
	sentryError = "permission_denied"
}

export class ErrorUnauthorized extends ErrorCustom {
	errorType = "unauthorized"
	// sentryError = "???"
}

export class ErrorInvalidParameters extends ErrorCustom {
	errorType = "invalid-parameters"
	sentryError = "invalid_argument"
}

export class ErrorBadRequest extends ErrorCustom {
	errorType = "bad-request"
	sentryError = "invalid_argument"
}

export class ErrorNotModified extends ErrorCustom {
	errorType = "not-modified"
	sentryError = "already_exists"
}

export class ErrorNotFound extends ErrorCustom {
	errorType = "not-found"
	sentryError = "not_found"
}
