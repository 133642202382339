import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import PublishIcon from '@mui/icons-material/Publish';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

import { request, useAuth } from '#api';
import Form, { FormSection, InputMultiline, File, Select, InputText } from '#Form';
import Dialog from '#Dialog';
import { DisplayDateTime, DisplayUser } from '#Display';
import DialogTitle from './DialogTitle';
import { statuses, actionsDef, producerOptions } from './schemas'

export const TimelinePost = ({ _id, subject, handleRefresh }) => {
	const [ data, setData ] = useState({})
	const [ isRO, setRO ] = useState(true)

	useEffect(() => {
		setRO(Boolean(_id))
		_id && request('/cabinet-exchange/timeline/get', { _id }).then(setData)
	}, [ _id ])

	const onSubmit = async () => {
		await request('/cabinet-exchange/timeline/post', { ...data, subject })
		setData({})
		handleRefresh?.()
	}

	const formProps = {
		_id,
		data, setData,
		onSubmit: !isRO && onSubmit,
		submitLabel: 'Publish',
		submitIcon: <PublishIcon />,
	}

	const title = useMemo(() => {
		if(!isRO)
			return 'New post'
		return (
			<Typography variant='h6' color="primary">
				<DisplayDateTime ts={data?.publishTS} /> - <DisplayUser uid={data?.author} />
			</Typography>
		)
	}, [ isRO, data ])

	return (
		<Form {...formProps}>
			<FormSection title={title}>
				{ Boolean(isRO && data?.workflow?.action) && (
					<Box sx={{ mb:2 }}>
						<Typography component="span">
							Action:
						</Typography>
						<Typography component="span" sx={{ ml:1, mr:1, verticalAlign:'middle' }}>
							{actionsDef.find(def => def.tag === data.workflow.action)?.icon}
						</Typography>
						<Typography component="span">
							{actionsDef.find(def => def.tag === data.workflow.action)?.label}
						</Typography>
					</Box>
				) }

				{ Boolean(!isRO || data?.message) && (
					<InputMultiline label="Message" name="message" disabled={isRO} />
				) }

				{ Boolean(!isRO || data?.attachments?.length) && (
					<File
						label="Attachments"
						name="attachments"
						uploadEndpoint="/cabinet-exchange/attachment/add"
						getEndpoint="/cabinet-exchange/attachment/get"
						downloadEndpoint="/cabinet-exchange/attachment/download"
						disabled={isRO}
					/>
				) }
			</FormSection>
		</Form>
	)
}

export const TimelineDialog = ({ _id, item, handleClose }) => {
	const auth = useAuth()
	const [ posts, setPosts ] = useState({})

	const handleRefresh = () => _id ? request('/cabinet-exchange/timeline/scan', { subject:_id }).then(setPosts) : setPosts({})
	useEffect(() => {
		handleRefresh()
	}, [ _id ])

	const open = useMemo(
		() => Boolean(_id),
		[ _id ],
	)

	const isRW = auth.check('request-timeline-post')

	return (
		<Dialog
			handleClose={handleClose}
			open={open}
			title={<DialogTitle item={item} title="Timeline" />}
			width="lg"
		>
			{ Boolean(posts?.count) ?
				posts?.items.map(post => (
					<TimelinePost key={post} _id={post} subject={_id} handleRefresh={handleRefresh} />
				)) : (
					<Box sx={{ m:3 }}>
						<Typography variant='body2'>No posts found related to this subject</Typography>
					</Box>
				)
			}
			{ isRW && <TimelinePost key="_new" subject={_id} handleRefresh={handleRefresh} />}
		</Dialog>
	)
}


export const ActionPost = ({ _id, subject, subjectActions, handleClose }) => {
	const [ data, setData ] = useState({})

	const actionsOpts = useMemo(() =>
		actionsDef
			.filter(def => subjectActions?.includes(def.tag))
			.map(def => ({
				value: def.tag,
				icon: def.icon,
				label: def.label,
			})),
		[ subjectActions ],
	)

	const onSubmit = async () => {
		await request('/cabinet-exchange/action/trigger', { ...data, subject })
		setData({})
		handleClose?.()
	}

	const formProps = {
		_id,
		data, setData,
		onSubmit,
		submitLabel: 'Submit',
		submitIcon: <PublishedWithChangesIcon />,
	}

	return (
		<Form {...formProps}>
			<FormSection title="Trigger new action">
				<Select label="Action" name="action" options={actionsOpts} emptyLabel="-- choose an available action --" required />
				<InputMultiline label="Message" name="message" />
				<File
					label="Attachments"
					name="attachments"
					uploadEndpoint="/cabinet-exchange/attachment/add"
					getEndpoint="/cabinet-exchange/attachment/get"
					downloadEndpoint="/cabinet-exchange/attachment/download"
				/>
			</FormSection>
		</Form>
	)
}

export const ActionDialog = ({ _id, item, handleClose }) => {
	const open = useMemo(
		() => Boolean(_id),
		[ _id ],
	)

	return (
		<Dialog
			handleClose={handleClose}
			open={open}
			title={<DialogTitle item={item} title2="Trigger action" />}
			width="lg"
		>
			<ActionPost subject={_id} subjectActions={item?.actions || []} handleClose={handleClose} />
		</Dialog>
	)
}
