import React, { useMemo } from 'react'
import Dialog from '#Dialog'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import { actionsDef } from './schemas'
import DialogTitle from './DialogTitle';

const EventsHistory = ({ _id, item, handleClose }) => {
	const open = useMemo(
		() => Boolean(_id),
		[ _id ],
	)

	const logItems = useMemo(
		() => item?.log?.items || [],
		[ item ],
	)

	return (
		<Dialog
			handleClose={handleClose}
			open={open}
			title={<DialogTitle item={item} title="Events Log" />}
			width="md"
		>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }}>
					<TableHead>
						<TableRow>
							<TableCell sx={{ fontWeight:'bold' }}>When</TableCell>
							<TableCell sx={{ fontWeight:'bold' }}>Who</TableCell>
							<TableCell sx={{ fontWeight:'bold' }}>Action triggered</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
					{ Boolean(logItems.length) && logItems.map((logEntry, logIdx) => (
						<TableRow key={logIdx} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell>{ new Date(logEntry?.occurredAt).toString() }</TableCell>
							<TableCell>{logEntry.uname || '-'}</TableCell>
							<TableCell>
								<Box sx={{ verticalAlign:'middle', display:'inline', mr:1 }}>
									{actionsDef.find(actionDef => actionDef.tag === logEntry.actionTag)?.icon} 
								</Box>
								{actionsDef.find(actionDef => actionDef.tag === logEntry.actionTag)?.label}
							</TableCell>
						</TableRow>
					))}
					</TableBody>
				</Table>
			</TableContainer>
		</Dialog>
	)
}
export default EventsHistory
