import React from 'react';
import { useParams } from 'react-router-dom';
import { TplWSidebar as Tpl } from '#Template'

import { RequestForm } from './EditForm'

export default function CabinetExchangeCreate() {
	const { _id } = useParams()

	return (
		<Tpl title="Submit new request">
			<RequestForm _id={_id} />
		</Tpl>
	)
}
