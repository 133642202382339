import React, { useState } from 'react';
import { Link } from "react-router-dom";

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';

function PadItem({ icon, label, linkTo, onClick, show=true, sx={} }) {
	const [ loading, setLoading ] = useState(false)

	const handleClick = async event => {
		setLoading(true)
		await onClick?.(event)
		setLoading(false)
	}

	const btnParams = {
		sx,
		onClick: handleClick,
	}
	if(!show)
		btnParams.sx.display = 'none'
	if(linkTo) {
		btnParams.component = Link
		btnParams.to = linkTo
	}

	return (
		<Tooltip title={label}>
			<Button {...btnParams}>
				{icon || label}
			</Button>
		</Tooltip>
	)
}

export default function ButtonPad({ def }) {
    return (
		<ButtonGroup variant="outlined" sx={{ verticalAlign:'middle' }}>
			{ def.map((defItem, itemIdx) =>
				<PadItem key={itemIdx} {...defItem} />
			)}
		</ButtonGroup>
    )
}
