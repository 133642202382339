export class Auth {
	constructor(info) {
		this.uid = info?.uid || undefined
		this.uname = info?.uname || undefined
		this.acl = info?.acl || []
	}

	check = (aclTag) => {
		if(!this.uid)
			return false
		if(aclTag)
			return Boolean(this.acl.includes(aclTag))
		return true
	}
}
