import { Dialog as DialogMui, DialogTitle } from '@mui/material';

export const dialogConfirm = ({ msg, onConfirm, onCancel }) => {
	window.confirm(msg) === true ? onConfirm?.() : onCancel?.()
}

const Dialog = ({ title, open, handleClose, children, width }) => {
	const fullWidth = Boolean(width)
	const maxWidth = width || 'sm'
	return (
		<DialogMui onClose={handleClose} open={Boolean(open)} fullWidth={fullWidth} maxWidth={maxWidth}>
			{/* <TplConfig backTo={handleClose}> */}
				{ title && <DialogTitle>{title}</DialogTitle> }
				{children}
			{/* </TplConfig> */}
		</DialogMui>
	)
}
export default Dialog